import { addMonths, startOfMonth } from 'date-fns';

export type CalculationResult = {
  options: CalculatorOptions;
  transitions: CompoundHistory[];
};

export interface CalculatorOptions {
  initialAmount: number;
  investFrequency: 12 | 4 | 1; // unit - month
  frequentInvestment: number;
  period: number; // unit - year
  interestPercentage: number;
}

export interface CompoundHistory {
  time: Date;
  amount: number;
  principalAmount: number;
  totalInterest: number;
}

export type CompoundResult = {
  startDate: Date;
  monthlyHistories: CompoundHistory[];
  yearlyHistories: CompoundHistory[];
};

export const formatNumber = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format;

export const compactFormatNumber = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'long',
  maximumFractionDigits: 0,
}).format;

const calculateInterest = (principalAmount: number, interestPercentage: number) => {
  const interest = principalAmount * (interestPercentage / 100);
  return { amount: principalAmount + interest, interest };
};

export const calculateCompound = (options: CalculatorOptions): CompoundResult => {
  const startDate = startOfMonth(new Date());
  const monthlyInterestPercentage = options.interestPercentage / 12;
  const initialHistory: CompoundHistory = {
    time: startDate,
    amount: options.initialAmount,
    principalAmount: options.initialAmount,
    totalInterest: 0,
  };

  const monthlyHistories: CompoundHistory[] = [initialHistory];
  const yearlyHistories: CompoundHistory[] = [initialHistory];

  for (let i = 0; i < options.period * 12; i++) {
    const lastHistory = monthlyHistories[monthlyHistories.length - 1];
    const result = calculateInterest(lastHistory.amount, monthlyInterestPercentage);
    const time = addMonths(startDate, i + 1);
    const isInvestTime = (i + 1) % options.investFrequency === 0;
    const frequentInvestment = isInvestTime ? options.frequentInvestment : 0;
    const amount = result.amount + frequentInvestment;
    const principalAmount = lastHistory.principalAmount + frequentInvestment;
    const totalInterest = amount - principalAmount;
    const newHistory: CompoundHistory = {
      time,
      amount,
      principalAmount,
      totalInterest,
    };
    monthlyHistories.push(newHistory);

    if ((monthlyHistories.length - 1) % 12 === 0) {
      yearlyHistories.push(newHistory);
    }
  }
  return {
    startDate,
    monthlyHistories,
    yearlyHistories,
  };
};
