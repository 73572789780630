import { ThemeProvider } from '@mui/material';
import { theme } from '../theme';
import CompoundPage from './compound/CompoundPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CompoundPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
