import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import classNames from 'classnames';
import styles from './Select.module.scss';

export type SelectProps = MuiSelectProps & {
  label?: string;
};

export const Select = ({ className, label, name, ...props }: SelectProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <MuiSelect className={styles.select} size="small" name={name} {...props} />
    </div>
  );
};
