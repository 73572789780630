import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './Card.module.scss';

export type CardProps = {
  className?: string;
} & JSX.IntrinsicElements['div'];

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, ...props }: CardProps, ref) => {
    return <div className={classNames(styles.root, className)} ref={ref} {...props} />;
  }
);
