import { differenceInYears, startOfYear } from 'date-fns';
import { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useIsDesktop } from '../../App.hooks';

import { compactFormatNumber, CompoundHistory, formatNumber } from '../CompoundPage.hooks';

interface CompoundChartProps {
  histories: CompoundHistory[];
  className?: string;
}

const interestColor = 'rgb(53, 162, 235)'; // #EAC744
const investColor = 'rgb(255, 99, 132)'; // #7CAE88

function CompoundChart({ histories, className }: CompoundChartProps) {
  const isDesktop = useIsDesktop();

  const ticks = useMemo(() => {
    const ticks = [];
    const chunkSize = Math.trunc(histories.length / 4);
    for (let i = 0; i < histories.length; i += chunkSize) {
      const { time } = histories[i];
      ticks.push(startOfYear(time).getTime());
    }
    return ticks;
  }, [histories]);

  return (
    <ResponsiveContainer className={className} width="100%" height={500}>
      <AreaChart data={histories}>
        <defs>
          <linearGradient id="colorUv" x1="0.3" y1="0.3" x2="0" y2="1">
            <stop offset="5%" stopColor={interestColor} stopOpacity={0.8} />
            <stop offset="95%" stopColor={interestColor} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0.3" y1="0.3" x2="0" y2="1">
            <stop offset="5%" stopColor={investColor} stopOpacity={0.8} />
            <stop offset="95%" stopColor={investColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          tickFormatter={(value) => `Year ${differenceInYears(value, histories[0].time)}`}
          tick={{ fontSize: '0.9em' }}
          ticks={ticks}
        />
        <YAxis
          width={isDesktop ? 90 : 70}
          tick={{ fontSize: '0.9em' }}
          tickFormatter={compactFormatNumber}
        />
        {/* @ts-ignore*/}
        <Tooltip<CompoundHistory[keyof CompoundHistory], keyof CompoundHistory>
          labelFormatter={(value) => `Year ${differenceInYears(value, histories[0].time)}`}
          formatter={(value, name) => {
            switch (name) {
              case 'amount':
                return [formatNumber(value), 'Amount Result'];
              case 'principalAmount':
                return [formatNumber(value), 'Principal Amount'];
              default:
                return value;
            }
          }}
        />
        <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
        <Area
          type="monotone"
          dataKey="amount"
          stroke={interestColor}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="principalAmount"
          stroke={investColor}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default CompoundChart;
