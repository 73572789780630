import {
  CellContext,
  ColumnDefTemplate,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { differenceInYears } from 'date-fns';
import { TanstackTable } from '../../Table/TanstackTable';
import { CompoundHistory, formatNumber } from '../CompoundPage.hooks';
import styles from './CompoundTable.module.scss';

interface CompoundTableProps {
  histories: CompoundHistory[];
  className?: string;
}

const columnHelper = createColumnHelper<CompoundHistory>();

const formatCurrency: ColumnDefTemplate<CellContext<CompoundHistory, number>> = (value) =>
  formatNumber(value.getValue());

const columns = [
  columnHelper.accessor('time', {
    cell: (info) => {
      const startTime = info.table.options.data[0].time;
      const yearDuration = differenceInYears(info.getValue(), startTime);
      return `Year ${yearDuration}`;
    },
    header: 'Time',
  }),
  columnHelper.accessor('principalAmount', {
    header: 'Principal',
    cell: formatCurrency,
  }),
  columnHelper.accessor('totalInterest', {
    header: 'Interest',
    cell: formatCurrency,
  }),
  columnHelper.accessor('amount', {
    header: 'Total',
    cell: formatCurrency,
  }),
];

function CompoundTable({ histories, className }: CompoundTableProps) {
  const table = useReactTable({
    data: histories,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TanstackTable className={classNames(styles.root, className)} table={table} />;
}

export default CompoundTable;
