import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000033',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.375rem',
          padding: '8px 16px',
          fontSize: '0.875rem',
          fontWeight: 700,
        },
      },
    },
  },
});
