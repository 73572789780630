import classNames from 'classnames';
import React from 'react';
import styles from './Input.module.scss';

export type InputProps = {
  className?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({ className, label, name, ...props }: InputProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input className={styles.input} name={name} {...props} />
    </div>
  );
};
