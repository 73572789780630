import { Container } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';

import { Card } from '../Card/Card';
import CompoundChart from './Chart/CompoundChart';
import { calculateCompound, CalculatorOptions, formatNumber } from './CompoundPage.hooks';
import styles from './CompoundPage.module.scss';
import { CompoundForm } from './Form/CompoundForm';
import CompoundTable from './Table/CompoundTable';

function CompoundPage() {
  const [optionsHistories, setOptionsOptionsHistories] = useState<CalculatorOptions[]>([]);
  const [currentOptions, setCurrentOptions] = useState<CalculatorOptions>();
  const resultElementRef = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback(
    (options: CalculatorOptions) => {
      const sameOptionsIndex = optionsHistories.findIndex((each) => {
        return (
          each.initialAmount === options.initialAmount &&
          each.investFrequency === options.investFrequency &&
          each.frequentInvestment === options.frequentInvestment &&
          each.period === options.period &&
          each.interestPercentage === options.interestPercentage
        );
      });

      if (sameOptionsIndex === -1) {
        setOptionsOptionsHistories([...optionsHistories, options]);
      } else {
        const newOptionsHistories = [...optionsHistories];
        newOptionsHistories.splice(sameOptionsIndex, 1);
        setOptionsOptionsHistories([...newOptionsHistories, options]);
      }
      setCurrentOptions(options);

      setTimeout(() => {
        resultElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    },
    [optionsHistories]
  );

  const data = useMemo(() => {
    if (!currentOptions) return undefined;
    return calculateCompound(currentOptions);
  }, [currentOptions]);

  return (
    <div className={styles.root}>
      <Container maxWidth="sm">
        <Card className={styles.formCard}>
          <div className={styles.header}>
            <h1 className={styles.title}>Compound Interest Calculator</h1>
            <p className={styles.description}>Calculate the future value of your investment.</p>
          </div>
          <CompoundForm className={styles.form} onSubmit={handleSubmit} />
        </Card>
      </Container>
      {data && currentOptions && (
        <Container maxWidth="xl">
          <Card className={styles.resultCard} ref={resultElementRef}>
            <h2 className={styles.resultCardTitle}>
              Calculation Result{' '}
              <small>
                In {currentOptions.period} years, you will have{' '}
                <strong>
                  {formatNumber(data.yearlyHistories[data.yearlyHistories.length - 1].amount)}
                </strong>
              </small>
            </h2>
            <div className={styles.resultCardBody}>
              {/* TODO: periodType button을 추가하여 table의 정보를 Monthly 또는 Yearly로 볼수있게 개선 */}
              <>
                <CompoundChart className={styles.chart} histories={data.yearlyHistories} />
                <CompoundTable className={styles.table} histories={data.yearlyHistories} />
              </>
            </div>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default CompoundPage;
