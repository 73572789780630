import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback } from 'react';
import { Input } from '../../Input/Input';
import { Select } from '../../Select/Select';
import { CalculatorOptions } from '../CompoundPage.hooks';
import { INITIAL_VALUES, investFrequencyMap, schema } from './CompoundForm.hooks';
import styles from './CompoundForm.module.scss';

export type CompoundFormProps = {
  onSubmit: (options: CalculatorOptions) => void;
  className?: string;
};

export const CompoundForm = ({ onSubmit, className }: CompoundFormProps) => {
  const handleSubmit = useCallback(
    (options: CalculatorOptions) => {
      onSubmit(options);
    },
    [onSubmit]
  );

  return (
    <Formik initialValues={INITIAL_VALUES} validationSchema={schema} onSubmit={handleSubmit}>
      {({ errors }) => (
        <Form className={classNames(styles.root, className)}>
          <div className={styles.body}>
            <Field name="initialAmount">
              {({ field }: FieldProps) => (
                <Input
                  type="number"
                  label="Initial Amount ($)"
                  placeholder="Enter your principal amount"
                  required
                  {...field}
                />
              )}
            </Field>
            {errors.initialAmount && <div className={styles.error}>{errors.initialAmount}</div>}
            <Field name="frequentInvestment">
              {({ field }: FieldProps) => (
                <Input
                  type="number"
                  label="Frequent Investment ($)"
                  placeholder="Enter your frequent invenstment amount"
                  {...field}
                />
              )}
            </Field>
            {errors.frequentInvestment && (
              <div className={styles.error}>{errors.frequentInvestment}</div>
            )}
            <Field name="investFrequency">
              {({ field }: FieldProps) => (
                <Select label="Invest Frequency" {...field}>
                  <MenuItem value={1}>{investFrequencyMap[1]}</MenuItem>
                  <MenuItem value={4}>{investFrequencyMap[4]}</MenuItem>
                  <MenuItem value={12}>{investFrequencyMap[12]}</MenuItem>
                </Select>
              )}
            </Field>
            {errors.investFrequency && <div className={styles.error}>{errors.investFrequency}</div>}
            <Field name="period">
              {({ field }: FieldProps) => (
                <Input
                  type="number"
                  label="Time Period (Years)"
                  placeholder="Enter time preiod in years"
                  required
                  {...field}
                />
              )}
            </Field>
            {errors.period && <div className={styles.error}>{errors.period}</div>}
            <Field name="interestPercentage">
              {({ field }: FieldProps) => (
                <Input
                  type="number"
                  label="Interest Rate(%)"
                  placeholder="Enter your interest rate"
                  required
                  {...field}
                />
              )}
            </Field>
            {errors.interestPercentage && (
              <div className={styles.error}>{errors.interestPercentage}</div>
            )}
          </div>
          <div className={styles.action}>
            <Button className={styles.button} variant="contained" type="submit">
              Calculate
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
