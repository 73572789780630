import * as yup from 'yup';
import { CalculatorOptions } from '../CompoundPage.hooks';

export const INITIAL_VALUES: CalculatorOptions = {
  initialAmount: 0,
  investFrequency: 1,
  frequentInvestment: 1000000,
  period: 20,
  interestPercentage: 14,
};

export const investFrequencyMap = {
  1: 'monthly',
  4: 'quarterly',
  12: 'yearly',
};

export const schema = yup.object().shape({
  initialAmount: yup.number().required('Initial Amount is required'),
  investFrequency: yup.number().oneOf([1, 4, 12]).required('Invest Frequency is required'),
  frequentInvestment: yup.number().optional().default(0),
  period: yup.number().min(1).required('Time Period is required'),
  interestPercentage: yup.number().min(0.1).required('Interest Percentage is required'),
});
